import { post, get } from '@/utils/http'
import { downFile } from '@/api/public.js'
import axios from 'axios'; // 引入axios


// 深度研究关注分页列表(注意传入报告类型)
export function deep_attention_page(params) {
    return post({ url: '/service-insight/deep_record/deep_attention_page', params })
}

// 报告基本信息
export function deep_detail(params) {
    return get({ url: '/service-insight/deep_record/deep_detail', params })
}
// 01、查看或预览报告
// export function deep_show_report(params) {
//     axios.post('/service-insight/deep_record/show-report', params, {

//         responseType: 'arraybuffer'
//     })
//         .then(response => {
//             return response.data
//             // 这里 response.data 就是字节流数据
//         })
//         .catch(error => {
//             console.error('错误',error);
//         });
// }

// 查看或预览报告
export const deep_show_report = (params) =>
    downFile(
        '/service-insight/deep_record/show-report',
        params,
        "arraybuffer",
    )

// 01、下载报告
export function deep_down_report(params) {
    return post({ url: '/service-insight/deep_record/down-report', params })
}

// 获取报告点赞关注数量
export function deep_like(params) {
    return get({ url: '/service-insight/deep_record/deep_like', params })
}

// 深度研究列表(注意传入报告类型)
export function deep_list(params) {
    return get({ url: '/service-insight/deep_record/deep_list', params })
}

// 获取报告留言录
export function deep_message(params) {
    return post({ url: '/service-insight/deep_record/deep_message', params })
}

// 深度研究分页列表(注意传入报告类型)
export function deep_page(params) {
    return post({ url: '/service-insight/deep_record/deep_page', params })
}

// 留言录保存修改
export function deep_save_message(params) {
    return post({ url: '/service-insight/deep_record/deep_save_message', params })
}

// 报告内容类型维度
export function deep_type(params) {
    return get({ url: '/service-insight/deep_record/deep_type', params })
}
// 取消关注报告
export function close_deep_attention(params) {
    return get({ url: '/service-insight/deep_record/close_deep_attention', params })
}
// 关注报告
export function deep_attention(params) {
    return get({ url: '/service-insight/deep_record/deep_attention', params })
}
// 点赞报告
export function deep_add_like(params) {
    return get({ url: '/service-insight/deep_record/deep_add_like', params })
}
// 取消点赞报告
export function close_deep_like(params) {
    return get({ url: '/service-insight/deep_record/close_deep_like', params })
}
// 下载报告后请求
export function down_deep(params) {
    return get({ url: '/service-insight/deep_record/down_deep', params })
}
// 阅读报告后请求
export function read_deep(params) {
    return get({ url: '/service-insight/deep_record/read_deep', params })
}







